import React, { Fragment } from "react"
import { graphql } from "gatsby"
import Helmet from "../components/Helmet"
import Header from "../components/Header"
import One from "../blogPage/One"
import Three from "../blogPage/Three"
import Footer from "../components/Footer"

let metaJSON = require("./meta.json")

function getMeta(title, slug) {
  let meta = metaJSON.find(obj => {
    return obj.slug === slug
  })

  if (meta) {
    return {
      title: meta.title,
      description: meta.description,
    }
  } else {
    return {
      title,
      description:
        "Keep up with the market and how-to-win strategies in the ETF Stock Report Blog.",
    }
  }
}

const BlogPost = ({ data }) => {
  const post = data.allWpPost.nodes[0]
  const meta = getMeta(post.title, post.slug)

  return (
    <Fragment>
      <Helmet title={meta.title} descr={meta.description} slug={post.uri} />
      <Header />
      <One title={post.title} />
      <Three date={post.date} content={post.content} />
      <Footer />
    </Fragment>
  )
}

export default BlogPost

export const query = graphql`
  query ($slug: String!) {
    allWpPost(filter: { slug: { eq: $slug } }) {
      nodes {
        title
        slug
        excerpt
        uri
        date
        content
      }
    }
  }
`
