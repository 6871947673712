import React from "react"
import * as styles from "./styles.module.css"

function fmtDate(str) {
  const obj = new Date(str)
  const m = obj.getMonth()
  const d = obj.getDate()
  const y = obj.getFullYear()
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ]

  return `${months[m]} ${d}, ${y}`
}

const Three = ({ date, content }) => {
  return (
    <section className={styles.three}>
      <div className={styles.container}>
        <div dangerouslySetInnerHTML={{ __html: content }} />

        <p className={styles.date}>Posted: {fmtDate(date)}</p>
      </div>
    </section>
  )
}

export default Three
